import {box, randomBytes} from 'tweetnacl'
import {
  decodeUTF8,
  encodeUTF8,
  encodeBase64,
  decodeBase64
}  from 'tweetnacl-util'

export function generateKeypair() {
  const {secretKey, publicKey} = box.keyPair()
  return [encodeBase64(secretKey), encodeBase64(publicKey)]
}

const newNonce = () => randomBytes(box.nonceLength)
const prepareKey = (secretKey, publicKey) => box.before(decodeBase64(publicKey), decodeBase64(secretKey))

export const encrypt = (secretKey, publicKey, secret) => {
  const secretOrSharedKey = prepareKey(secretKey, publicKey)
  const nonce = newNonce();
  const messageUint8 = decodeUTF8(secret);
  const encrypted = box.after(messageUint8, nonce, secretOrSharedKey)

  const fullMessage = new Uint8Array(nonce.length + encrypted.length);
  fullMessage.set(nonce);
  fullMessage.set(encrypted, nonce.length);

  const base64FullMessage = encodeBase64(fullMessage);
  return base64FullMessage;
};

export const decrypt = (secretKey, publicKey, messageWithNonce) => {
  const secretOrSharedKey = prepareKey(secretKey, publicKey)
  const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce);
  const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength);
  const message = messageWithNonceAsUint8Array.slice(
    box.nonceLength,
    messageWithNonce.length
  );

  const decrypted = box.open.after(message, nonce, secretOrSharedKey)

  if (!decrypted) {
    console.error('Could not decrypt message');
  }

  const base64DecryptedMessage = encodeUTF8(decrypted);
  return base64DecryptedMessage;
};

export function getPrivateKey() {
  return JSON.parse(localStorage.getItem('myKeypair')).privateKey
}
