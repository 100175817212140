import axios from 'axios'

const fetchJson = path =>
  axios.get(path, {
    responseType: 'json',
    timeout: 30000,
  })

export async function fetchMetadata(id) {
    try {
        const { data } = await fetchJson(`${window.metadataPrefixURL}/${id}/metadata.json`)
        return data
    } catch (err) {
        console.error('fetchMetadata failed', err)
        return false
    }
}

export async function fetchPublicKey(meta) {
  try {
    const { data } = await fetchJson(meta.downloadPublicKey.url)
    return data;
  } catch(err) {
    console.error('fetchPublicKey failed', err)
    return false
  }
}

export async function uploadPublicKey(meta, payload) {
  try {
    const result = await axios.put(
      meta.uploadPublicKey.url,
      payload
    )
    return result
  } catch(err) {
    console.error('uploadPublicKey failed', err)
    return false
  }
}

export async function downloadEncryptedData(meta) {
  try {
    const result = await fetchJson(meta.downloadEncryptedData.url)
    return result.data;
  } catch(err) {
    console.error('fetchPublicKey failed', err)
    return false
  }
}

export async function uploadEncryptedData(meta, payload) {
  try {
    const result = await axios.put(
      meta.uploadEncryptedData.url,
      payload
    )
    return result
  } catch(err) {
    console.error('uploadPublicKey failed', err)
    return false
  }
}


async function doRequest(uid) {
  const meta = await fetchMetadata(uid);
  const publicKeyPayload = await fetchPublicKey(meta);
  console.log('publicKeyPayload', publicKeyPayload)

  if (!publicKeyPayload) {
    const r = await uploadPublicKey(
      meta,
      { publicKey: 'f2uk5rker6XPDk3EycXuD3IjQOrUJIwbG0aTNnBFIgM=' }
    );

    if (!r) {
      console.log('expired')
    } else {
      console.log('uploadPublicKey OK', r)
    }
  }

  const encData = await downloadEncryptedData(meta);
  console.log('encData', encData)

  if (!encData) {
    const res = await uploadEncryptedData(
      meta,
      { encrypted: '123' }
    );

    if (!res) {
      console.log('expired')
    } else {
      console.log('uploadEncryptedData OK', res)
    }
  }

}

window.doRequest = doRequest