const mainPrimary = '#3882E0'
const mediumPrimary = '#88BDF0'
const palePrimary = '#529CF9'

const mainText = '#FDFDFD'
const secondaryText = '#8C8C8C'
const placeholderText = '#868686'

const success = '#32CD88'
const warning = '#FECE65'
const error = '#F91628'


const mineShaft = '#333333'
const darkJungle = '#1D1D1D'
const jumbo = '#888888'

const white = '#FFFFFF'

export default {
  mainPrimary,
  mediumPrimary,
  palePrimary,
  mainText,
  secondaryText,
  placeholderText,
  success,
  warning,
  error,
  white,
  mineShaft,
  darkJungle,
}