import React from 'react'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'

import styles from './styles'
import Link from "./link";

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText};
  margin-top: 24px;
  margin-bottom: 24px;
`

const P = styled.p`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
  
  & a {
    color: ${styles.colors.palePrimary}
  }
`

export default function() {
  return <div className='info-page'>
    <H1>Secure Password Sharing</H1>
    <P>JetBrains Share allows you to send a password in a secure way.
      The password is transferred encrypted by the recipient’s one-time public key, while the private key for the decryption is
      stored in the recipient's browser only. What is important is that all the communications can be public, it's just important to deliver the first link from recipient to the sender in the original, unmodified way.</P>

    <P>You can use <Link href='/generate-key'>offline mode</Link> and pass the data using any reliable instant messaging app or email. In this mode the page does not send any data anywhere.</P>

    <P>This service also works in combination with JetBrains Slack bot, Hector. It is initiated via
      &quot;/hector&nbsp;share&nbsp;@username&quot;&nbsp;command.
      The bot then sends the instructions to open this web site. In this mode, the pages exchange a
      public key and an encrypted password via a server, but the server does not get the ability to get the password
      value as it never gets the private key.</P>

    <P>We have made the choice to increase security by starting the sharing on the recipient's end where the browser generates one-time keypair and stores the private key in the browser's local storage. Public key is then encoded into the URL which the sender opens. The final URL to the receiver contains the password encrypted by the public key. This scheme has an important advantage over symmetric key cryptography as it is not vulnerable to eavesdropping of all the communications between the parties. It is still vulnerable to the man-in-the-middle attack if the adversary can replace the public key transfer. That is why it is important to send the link with the public key so that the link is delivered in unmodified form. Kudos to the authors of <a href='https://github.com/dchest/tweetnacl-js'>TweetNaCl.js</a> library which powers the encryption in the browser.</P>

    <P>The project was started during JetBrains Hackathon 2020.</P>
  </div>
}
