import React, {useState} from 'react'
import styled from 'styled-components'

import styles from './styles'
import Input from './input'
import InputLabel from './input-label'
import Button from './button'
import Spinner from './spinner'

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText};
  margin-top: 24px;
  margin-bottom: 24px;
`

const P = styled.p`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const Centered = styled.div`
  display:flex;
  flex-direction: row;
  height: ${props => props.height ? props.height : ' 100%'};
  justify-content: center;
  align-items: center;
`

const SENDER_STATES = {
  INITIAL_STATE: 'INITIAL_STATE',
  PUBLIC_KEY_RECEIVED: 'PUBLIC_KEY_RECEIVED',
  PASSWORD_SUBMITTED: 'PASSWORD_SUBMITTED',
  ERROR: 'ERROR'
}

export default function(props) {
  const {step, error, errorMessage, onSubmitPassword, metadata} = props
  const receiverName = (metadata && metadata.receiver && metadata.receiver.name)
    ? <strong style={{color: 'white'}}>{`@${metadata.receiver.name}`}</strong> 
    : <span>Someone</span>
  const [secret, setSecret] = useState('')

  return <div className='form-wrapper'>
    <H1>Share a Password</H1>
    <P>Send a password to {receiverName} in a secure way. Password will be encrypted with recipient's public key within this browser and the encrypted value will be uploaded to a shared storage. Only {receiverName} will be able to decrypt it.</P>
    {renderForm()}
    {error
      ? <P><span style={{color: styles.colors.error}}>{errorMessage}</span></P>
      : null}
  </div>

  function renderForm() {
    if (step === SENDER_STATES.INITIAL_STATE && !error) {
      return (
        <Centered height={'100px'}>
          <Spinner scale={1.3} color={styles.colors.white} />
        </Centered>
      )
    } else if (step === SENDER_STATES.PUBLIC_KEY_RECEIVED) {
      return (
        <>

          <InputLabel>Password to share</InputLabel>
          <Input
              width='100%'
              required={true}
              value={secret}
              onChange={setSecret}
              isPassword
            />
          <Button
            style={{width: '100%', marginTop: '24px'}}
            mode='hero'
            onClick={() => onSubmitPassword(secret)}
            disabled={secret == ''}
          >
            Share Password
          </Button>
        </>
      )
    } else if (step === SENDER_STATES.PASSWORD_SUBMITTED) {
      return (<P><span style={{color: styles.colors.success}}>Password has been submitted! You can now close this page.</span></P>)
    } else {
      return null
    }
  }
}
