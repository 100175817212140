import React from 'react'
import styled from 'styled-components'

import styles from './styles'

const Label = styled.label`
  display: block;
  box-sizing: border-box;
  text-align: left;
  ${styles.typography.inputLabel}
  max-width: 100%;
  padding: 0;
  padding-right: 24px;
  color: ${styles.colors.mainText};
  padding-bottom: 8px;
  margin-top: ${props => props.marginTop ? props.marginTop : '16px'};
`

export default function InputLabel({children, ...rest}) {
  return (
    <Label {...rest}>
      {children}
    </Label>
  )
}
