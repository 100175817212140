import React from 'react'
import styled, {keyframes} from 'styled-components'

import styles from './styles'

const StepperWrapper = styled.div`
  display:flex;
  flex-direction: row;
  height: ${props => props.height ? props.height : ' 100%'};
  justify-content: space-between;
  align-items: center;
`

const Step = styled.div`
  margin-top: 1px;
  height: 6px;
  background-color: ${props => props.color};
  width: auto;
  flex-grow: 1;
`

const PointContainer = styled.div`
  width: 8px;
  height: 8px;
`

const PointWapper = styled.div`
  position: relative;
  top: -4px;
  left: -4px;
`

const SegmentWrapper = styled.div`
flex-grow: 1;
display: inline-flex;
flex-direction: column;
`

const Segment = styled.div`
display: inline-flex;
`

const SegmentLabel = styled.div`
align-self: center;
margin-left: ${props => props.first ? 0 : '-16px'};
padding-top: 10px;

color: ${styles.colors.secondaryText};
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 23px;
text-align: center;
letter-spacing: 0.3px;
`

const Point = ({
  color = DEFAULT_COLOR,
}) => (
  <PointContainer color={color}>
    <PointWapper>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="6" stroke={color} strokeWidth="4"/>
      </svg>
    </PointWapper>
  </PointContainer>
);

const DEFAULT_COLOR = styles.colors.mainPrimary
const DISABLED_COLOR = styles.colors.mineShaft

const Stepper = ({
  activeColor = DEFAULT_COLOR,
  inactiveColor  =  DISABLED_COLOR,
  steps = [],
  activeStepIndex = -1,
  className,
  ...restProps
}) => (
  <StepperWrapper className={className} {...restProps}>
      {steps.map((label, index) => {
          const color = index <= activeStepIndex ? activeColor : inactiveColor;
          return <SegmentWrapper key={label}>
              <Segment>
                  {index === 0 && <Point color={color}/>}
              <Step color={color}/>
              <Point color={color}/>
              </Segment>
              <SegmentLabel first={index === 0}>{label}</SegmentLabel>
          </SegmentWrapper>
      })}
  </StepperWrapper>
)
export default Stepper