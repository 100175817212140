import React from 'react'
import {Switch} from 'react-router'
import {Route} from 'react-router-dom'

import Header from './header'
import GenerateKey from './generate-key'
import Encrypt from './encrypt'
import Decrypt from './decrypt'
import Receive from './receive'
import Send from './send'
import About from './about'
import Main from './main'
import ReceiveContainer from './receive-container';
import SendContainer from './send-container';

export default function() {
  return <div className='app'>
      <Header />
      <div className='content'>
        <Switch>
          <Route path='/generate-key' children={({location, history})=>(<GenerateKey location={location} history={history}/>)}/>
          <Route path='/encrypt' children={({location})=>(<Encrypt location={location}/>)}/>
          <Route path='/decrypt' children={({location})=>(<Decrypt location={location}/>)}/>
          <Route path='/receive' children={({location})=>(<ReceiveContainer location={location}/>)}/>
          <Route path='/send' children={({location})=>(<SendContainer location={location}/>)}/>
          <Route path='/about' component={About}/>
          <Route path='/' component={Main}/>
        </Switch>
      </div>
    </div>
}
