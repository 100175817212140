import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {nanoid} from 'nanoid'

import styles from './styles'
import Input from './input'
import InputLabel from './input-label'
import Button from './button'


import { generateKeypair as _generateKeypair } from '../utils'
import { saveKeypair, readKeypair } from '../utils/storage'
import Link from "./link";

const DEFAULT_COPY_LABEL = 'Copy URL to clipboard'
const COPIED_COPY_LABEL = 'Copied!'

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText};
  margin-top: 24px;
  margin-bottom: 24px;
`

const P = styled.p`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const CenteredP = styled(P)`
  ${styles.typography.smallText}
  text-align: center;
`

const HorizontalFlex = styled.div`
  display:flex;
  flex-direction: row;
  height: 100%;
`

export default function({location, history}) {
  const queryParams = new URLSearchParams(location.search || '')
  const _secretId = queryParams.get('id')
  const [secretId, setSecretId] = useState(_secretId)
  const [keypair, setKeypair] = useState({publicKey: '', privateKey: ''})

  const [isCopied, setIsCopied] = useState(false)
  const [isButtonLabelCopied, setIsButtonLabelCopied] = useState(false)

  useEffect(()=>{
    if (!secretId) {
      const newId = nanoid()
      setSecretId(newId)
      generateKeypair(newId)
      history.push(`/generate-key?id=${newId}`)
    } else {
      const savedKeypair = readKeypair(secretId)
      setKeypair(savedKeypair)
    }
  },[])

  const [copyLabel, setCopyLabel] = useState(DEFAULT_COPY_LABEL)

  function getHrefToCopy() {
    if (!keypair.publicKey) {
      return 'keypair expired'
    }
    //note # sign: it comes from the hash history we use rn
    return `${window.location.origin}/#/encrypt?publicKey=${encodeURIComponent(keypair.publicKey)}&id=${secretId}`
  }
  function generateKeypair(newSecretId = secretId) {
    const [privateKey, publicKey] = _generateKeypair()
    setKeypair({privateKey, publicKey})
    saveKeypair(newSecretId, {privateKey, publicKey})
    setIsCopied(false)
    setIsButtonLabelCopied(false)
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(getHrefToCopy())
    setIsCopied(true)
    setIsButtonLabelCopied(true)
    setTimeout(_ => setIsButtonLabelCopied(false), 1000)
  }
  return <div className='form-wrapper'>
    <H1>Generate Keypair</H1>
    <P>Someone wants to send a password to you in a secure way using this service. This page does not send anything anywhere. It does encryption in the browser and temporarily stores private key in your browser's local storage.</P>
    <P><strong style={{color: 'white', fontWeight: 500}}>Send the URL below to the person who knows the password</strong> and then follow the link they send in return. It's important that no one can change this first link in transit and the sharer gets it exactly as generated. The link is not secret - it's OK to make it even public, it just should be the original one.</P>
    <P>If you want to send the password to someone instead, just send the link of <Link href='/generate-key'>this page</Link> to the person as the first step is to generate the key on the receiving end.</P>
    <InputLabel>URL to share</InputLabel>
    <HorizontalFlex>
      <Input
        width='100%'
        value={getHrefToCopy()}
      />
      <Button
        style={{height: '44px', marginLeft: '8px'}}
        onClick={() => generateKeypair()}>
        Regenerate Key
      </Button>
    </HorizontalFlex>

    <div>
      <Button style={{width: '100%', marginTop: '24px'}} mode='hero' onClick={copyToClipboard} disabled={!keypair.privateKey}>
        {isButtonLabelCopied ? COPIED_COPY_LABEL : DEFAULT_COPY_LABEL}
      </Button>
      {!keypair.privateKey && <CenteredP>Seems like your private key has expired. Please, regenerate keypair and request password one more time.</CenteredP>}
      {isCopied && <CenteredP>Now please send the URL to the person who knows the password</CenteredP>}
    </div>
  </div>
}
