import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { fetchPublicKey, fetchMetadata, uploadEncryptedData } from '../api';
import { encrypt, generateKeypair } from '../utils';

import Send from './send'

const POLLING_INTERVAL = 2000;

const SENDER_STATES = {
    INITIAL_STATE: 'INITIAL_STATE',
    PUBLIC_KEY_RECEIVED: 'PUBLIC_KEY_RECEIVED',
    PASSWORD_SUBMITTED: 'PASSWORD_SUBMITTED',
    ERROR: 'ERROR'
}

function sendReducer(state, action) {
    switch (action.type) {
        case SENDER_STATES.PASSWORD_SUBMITTED:
            return {...state, error: false, step: action.type}
        case SENDER_STATES.PUBLIC_KEY_RECEIVED:
            return {...state, error: false, step: action.type, publicKey: action.publicKey}
        case SENDER_STATES.ERROR:
            return {...state, error: true, errorMessage: action.message}
        case 'SET_SESSION_ID':
            return { ...state, error: false, sessionId: action.sessionId }
        case 'SET_METADATA':
            return { ...state, error: false, metadata: action.metadata }
        default:
            return { step: SENDER_STATES.INITIAL_STATE }
    }
}

const SendContainer = ({location}) => {
    const [state, dispatch] = useReducer(sendReducer, {step: SENDER_STATES.INITIAL_STATE});

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search || '')
        const sessionId = queryParams.get('id');
        if(sessionId) {
            dispatch({ type: "SET_SESSION_ID", sessionId })
        } else {
            dispatch({type: SENDER_STATES.ERROR, message: 'Sorry, wrong link. Check that the link is the same as you have received from the bot.'})
        }
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            if (state.sessionId) {
                const metadata = await fetchMetadata(state.sessionId)
                if (metadata) {
                    dispatch({ type: "SET_METADATA", metadata })
                } else {
                    dispatch({
                        type: SENDER_STATES.ERROR,
                        message: 'Sorry, something went wrong. Try reloading the page or initiate a new password sharing process from the start.'
                    })
                }
            }
        })()
    }, [state.sessionId]);

    useEffect(() => {
        if(state.step !== SENDER_STATES.INITIAL_STATE || !state.metadata){
            return
        }
        const interval = setInterval(async () => {
            const {publicKey} = await fetchPublicKey(state.metadata)
            if(publicKey){
                dispatch({type: SENDER_STATES.PUBLIC_KEY_RECEIVED, publicKey});
            }
        }, POLLING_INTERVAL)
        return () => clearInterval(interval)
    }, [state.step, state.metadata]);


    const onSubmitPassword = useCallback(async (password) => {
        try {
            const [pr, pu] = generateKeypair()
            const encryptedPassword = encrypt(pr, state.publicKey, password)
            const response = await uploadEncryptedData(state.metadata, { encryptedPassword, secretPublicKey: pu })
            if (response) {
                dispatch({ type: SENDER_STATES.PASSWORD_SUBMITTED })
            } else {
                dispatch({ type: SENDER_STATES.ERROR, message: "Error sending password" })
            }
        } catch (e) {
            dispatch({ type: SENDER_STATES.ERROR, message: `Error encrypting password: ${e.message}` })
        }

    }, [state.publicKey, state.metadata]);

    const [password, setPassword] = useState('')

    return (
        <Send {...state} onSubmitPassword={onSubmitPassword} />
    );
};

// <div>
        //     <pre style={{color:'white'}}>
        //         {JSON.stringify(state, null, '  ')}
        //     </pre>
        //     <input value={password} onChange={e => setPassword(e.target.value)}/>
        //     <button onClick={() => onSubmitPassword(password)}>Submit</button>
        // </div>

export default SendContainer;
