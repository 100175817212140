const SECRET_PREFIX = 'SHARE_'
const CLEANUP_CHECKOUT_TIMEOUT = 60 * 1000
const CLEANUP_TIMEOUT = 60 * 60 * 1000

cleanup()
setInterval(cleanup, CLEANUP_CHECKOUT_TIMEOUT)

export function saveKeypair(secretId, keypair) {
  localStorage.setItem(`${SECRET_PREFIX}${secretId}`, JSON.stringify({...keypair, timestamp: Date.now()}))
}
export function readKeypair(secretId) {
  return JSON.parse(localStorage.getItem(`${SECRET_PREFIX}${secretId}`)) || {}
}

function cleanup() {
  const secretKeys = Object.keys(localStorage).filter(key => key.indexOf(SECRET_PREFIX) === 0)
  const now = Date.now()
  secretKeys.forEach(key => {
    const { timestamp } = JSON.parse(localStorage.getItem(key)) || {}
    if (timestamp && now - timestamp >= CLEANUP_TIMEOUT) {
      console.info('removing secret ', key)
      localStorage.removeItem(key)
    }
  })
}
