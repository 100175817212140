import React from 'react';
import styled from 'styled-components';

const EyeIconInner = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props}>
            <path
                d="M12 18.122a11.283 11.283 0 0 1-9.729-5.621L1.981 12l.29-.501a11.23 11.23 0 0 1 19.457 0l.29.501-.29.501A11.283 11.283 0 0 1 12 18.122zM4.314 12a9.226 9.226 0 0 0 15.372 0 9.226 9.226 0 0 0-15.372 0z"/>
            <circle cx="12" cy="12" r="3"/>
        </svg>
    );
};

const HideIconInner = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" {...props}>
            <path
                d="M21.729 11.499a11.24 11.24 0 0 0-3.303-3.566l-1.042 1.693A9.27 9.27 0 0 1 19.686 12a9.284 9.284 0 0 1-6.217 3.988l-1.307 2.123a11.278 11.278 0 0 0 9.567-5.61l.29-.501zM16.152 3.47l-1.69 2.703A10.897 10.897 0 0 0 12 5.878a11.283 11.283 0 0 0-9.729 5.621l-.29.501.29.501a11.243 11.243 0 0 0 5.31 4.683L6.152 19.47l1.696 1.06 10-16zM4.314 12A9.277 9.277 0 0 1 12 7.878a8.588 8.588 0 0 1 1.33.107l-.685 1.096A2.88 2.88 0 0 0 12 9a3 3 0 0 0-3 3 2.95 2.95 0 0 0 .659 1.86l-1.01 1.614A9.284 9.284 0 0 1 4.314 12z"/>
        </svg>
    );
};

export const EyeIcon = styled(EyeIconInner)`
  fill: #888888;
  &:hover {
    fill: #FFFFFF
  }
`

export const HideIcon = styled(HideIconInner)`
  fill: #888888;
  &:hover {
    fill: #FFFFFF
  }
`

