import React from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import styled from 'styled-components'

import styles from './styles'

const LinkTag = styled(NavLink)`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  text-decoration: none;
  cursor: pointer;
  &:hover, &.active {
    color: ${styles.colors.white};
    text-decoration: none;
  }
`

export default function Link({children, href, ...rest}) {
  return (
    <LinkTag activeClassName='active' isActive={isActive(href)} to={href} {...rest}>
      {children}
    </LinkTag>
  )
}

function isActive(href) {
  if (href !== '/') {
    return undefined
  }
  return (_, location) => {
    return location.pathname === '/'
  }
}

Link.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string
}