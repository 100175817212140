import React, {useState, useRef} from 'react'

import styled from 'styled-components'

import styles from './styles'
import Input from './input'
import InputLabel from './input-label'
import Button from './button'

import {generateKeypair, encrypt as _encrypt} from '../utils'

const DEFAULT_COPY_LABEL = 'Copy to clipboard'
const COPIED_COPY_LABEL = 'Copied!'

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText};
  margin-top: 24px;
  margin-bottom: 24px;
`

const P = styled.p`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const CenteredP = styled(P)`
  ${styles.typography.smallText}
  text-align: center;
`


export default function({location}) {
  const queryParams = new URLSearchParams(location.search || '')
  const publicKey = decodeURIComponent(queryParams.get('publicKey'))
  const secretId = queryParams.get('id')
  const [encrypted, setEncrypted] = useState('')
  const [generatedPublicKey, setGeneratedPublicKey] = useState('')
  const [isCopied, setIsCopied] = useState(false)
  const [isButtonLabelCopied, setIsButtonLabelCopied] = useState(false)
  const secretInput = useRef(null)

  function encrypt(secret) {
    secretInput.current.validate()
    if (secret === '') {
      return
    }
    const [privateKey, _generatedPublicKey] = generateKeypair()
    setEncrypted(_encrypt(privateKey, publicKey, secret))
    setGeneratedPublicKey(_generatedPublicKey)
    setIsCopied(false)
    setIsButtonLabelCopied(false)
  }

  function getHrefToCopy() {
    //note # sign: it comes from the hash history we use rn
    return `${window.location.origin}/#/decrypt?secret=${encodeURIComponent(encrypted)}&publicKey=${encodeURIComponent(generatedPublicKey)}&id=${secretId}`
  }
  function copyToClipboard() {
    navigator.clipboard.writeText(getHrefToCopy())
    setIsCopied(true)
    setIsButtonLabelCopied(true)
    setTimeout(_ => setIsButtonLabelCopied(false), 1000)
  }
  return <div className='form-wrapper'>
    <H1>Encrypt Password</H1>
    <P>Send a password in a secure way. Only the person who have sent you this link will be able to decrypt it. This page does not send anything anywhere. It encrypts the entered password using the recipient's public key encoded in the URL.</P>

    <InputLabel>Password</InputLabel>
    <Input
      ref={secretInput}
      width='100%'
      autoFocus
      isPassword={true}
      required={true}
      onChange={e => encrypt(e)}
      placeholder={'Password your want to share'}
    />

    <InputLabel>URL to send back</InputLabel>
    <Input
      width='100%'
      value={encrypted ? getHrefToCopy() : undefined}
      placeholder={'Will be generated after password encryption'}
    />

    <div>
      <Button
        style={{width: '100%', marginTop: '24px'}}
        mode='hero'
        onClick={copyToClipboard}
        disabled={!encrypted}>
        {isButtonLabelCopied ? COPIED_COPY_LABEL : DEFAULT_COPY_LABEL}
      </Button>
      {isCopied && <CenteredP>Now please send the URL back to the person who have sent you this link</CenteredP>}
    </div>
  </div>
}
