import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import styles from './styles'
import { EyeIcon, HideIcon } from './eye-icons';

const Container = styled.div`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  height: ${props => props.multiline ? 'auto' : '44px'};
  width: ${props => props.width ? props.width : 'auto'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '8px'};
`

const InputTag = styled.input`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  ${styles.typography.input}
  color: ${props => {
    if (props.disabled) {
      return styles.colors.secondaryText
    } else {
      return styles.colors.mainText
    }
  }};
  border: 1px solid ${styles.colors.mineShaft};
  background-color: ${styles.colors.darkJungle};
  border-radius: 4px;
  text-align: left;
  padding: 12px 15px;
  transition: all .2s ease-in-out;
  outline: 0;
  line-height: 20px;
  ::selection {
    color: ${styles.colors.white};
    background: ${styles.colors.mainPrimary};
  }
  ::-moz-selection {
    color: ${styles.colors.white};
    background: ${styles.colors.mainPrimary};
  }
  border: 1px solid ${props => {
    if (props.focused) {
      return styles.colors.jumbo
    } else {
      return props.valid ? styles.colors.mineShaft : styles.colors.error
    }
  }};
  vertical-align: middle;
  ::placeholder {
    ${styles.typography.input}
  }
  &[type="password"] {
    font: large Verdana,sans-serif;
    font-size: 13px;
    letter-spacing: 1px;
    padding-right: 43px;
    &:placeholder {
      line-height: 20px;
    }
  }
  resize: none;
  min-height: ${props => props.multiline && '126px'};
`

const ErrorMessage = styled.span`
  display: block;
  box-sizing: border-box;
  text-align: left;
  ${styles.typography.smallText}
  max-width: 100%;
  padding: 0;
  padding-right: 24px;
  color: ${styles.colors.error};
  padding-top: 4px;
`

const StyledHideIcon = styled(HideIcon)`
  position: absolute;
  top: 10px;
  right: 14px;
`

const StyledEyeIcon = styled(EyeIcon)`
  position: absolute;
  top: 10px;
  right: 14px;
`

export class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    width: PropTypes.string,
    validate: PropTypes.func,
    value: PropTypes.string,
    isPassword: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    required: false,
    multiline: false,
    validate: null,
  }

  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      valid: true,
      empty: !props.defaultValue,
      errorMessage: '',
    }
  }

  handleInputRef = inputRef => {
    this.inputRef = inputRef
  }

  isValid () {
    return this.state.valid
  }

  validate = (callback) => {
    if (!callback) {
      callback = () => false
    }

    const value = this.inputRef.value
    const {validate, required} = this.props

    const validationError = validate && validate(value)
    const isValid = !validationError

    if (required && value === '') {
      this.setState({
        focused: false,
        valid: false,
        errorMessage: 'Required',
      }, callback)
      return false
    } else if (!isValid) {
      this.setState({
        focused: false,
        valid: false,
        errorMessage: validationError,
      }, callback)
      return false
    } else {
      this.setState({
        focused: false,
        valid: true,
        errorMessage: '',
      }, callback)
      return true
    }
  }

  onFocus = () => {
    this.setState({ focused: true })
    this.props.onFocus && this.props.onFocus()
  }

  value = () => {
    return this.inputRef.value
  }

  onBlur = () => {
    this.validate(this.props.onBlur)
  }

  handleChange = e => {
    const {onChange} = this.props
    this.setState({empty: !e.target.value})
    onChange && onChange(e.target.value)
  }

  focus = () => {
    this.inputRef.focus()
  }

  toggleShowPassword = () => {
    this.setState(({isShowPassword})=> ({isShowPassword: !isShowPassword}))
  }

  render() {
    const {
      placeholder,
      disabled,
      width,
      defaultValue,
      multiline,
      value,
      isPassword
    } = this.props

    const {
      empty,
      focused,
      valid,
      errorMessage,
      isShowPassword
    } = this.state


    return (
      <Container width={width} multiline={multiline}>
        <InputTag
          as={multiline && 'textarea'}
          multiline={multiline}
          type={isPassword && !isShowPassword ? "password" : "text"}
          spellCheck="false"
          defaultValue={defaultValue}
          focused={focused}
          valid={valid}
          value={value}
          empty={empty}
          disabled={disabled}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placeholder={placeholder}
          onChange={this.handleChange}
          ref={this.handleInputRef}
        />
        {isPassword && (isShowPassword ? <StyledHideIcon onClick={this.toggleShowPassword}/> : <StyledEyeIcon onClick={this.toggleShowPassword}/>)}
        {!focused && !valid
          ? <ErrorMessage>{errorMessage}</ErrorMessage>
          : null
        }
      </Container>
    )
  }
}

export default Input
