import React from 'react'
import {useRouteMatch} from 'react-router'

import Logo from './logo'
import Link from './link'
import StyledGithubIcon from './github-icon';

export default function Header() {
  return <div className='header'>
    <Logo />
    <div className='spacer'/>
    <div className='links'>
      <Link href='/generate-key'>Share a Password</Link>
      <Link href='/about'>About</Link>
      <a href={'https://github.com/JetBrains/hackathon2020-spwd-secure-password-sharing'} className={'gh-link'}>
        <StyledGithubIcon/>
      </a>
    </div>
  </div>
}
