import React, { useEffect, useReducer } from 'react';
import { downloadEncryptedData, fetchMetadata, uploadPublicKey } from '../api';
import { decrypt, generateKeypair } from '../utils';

import Receive from './receive'
import { saveKeypair, readKeypair } from '../utils/storage';

const POLLING_INTERVAL = 2000;

const RECEIVER_STATES = {
    INITIAL_STATE: 'INITIAL_STATE',
    PUBLIC_KEY_GENERATED: 'PUBLIC_KEY_GENERATED',
    PUBLIC_KEY_SUBMITTED: 'PUBLIC_KEY_SUBMITTED',
    PASSWORD_RECEIVED: 'PASSWORD_RECEIVED',
    PASSWORD_DECRYPTED: 'PASSWORD_DECRYPTED',
    ERROR: 'ERROR'
}

function receiveReducer(state, action) {
    switch (action.type) {
        case RECEIVER_STATES.PUBLIC_KEY_GENERATED:
            return {...state, error: false, step: action.type}
        case RECEIVER_STATES.PASSWORD_RECEIVED:
            return {...state, error: false, step: action.type, encryptedPassword: action.encryptedPassword, secretPublicKey: action.secretPublicKey}
        case RECEIVER_STATES.PASSWORD_DECRYPTED:
            return { ...state, error: false, step: action.type, decryptedPassword: action.decryptedPassword}
        case RECEIVER_STATES.PUBLIC_KEY_SUBMITTED:
            return { ...state, error: false, step: action.type, publicKey: action.publicKey, privateKey: action.privateKey }
        case RECEIVER_STATES.ERROR:
            return {...state, error: true, errorMessage: action.message}
        case 'SET_SESSION_ID':
            return { ...state, error: false, sessionId: action.sessionId }
        case 'SET_METADATA':
            return { ...state, error: false, metadata: action.metadata }
        default:
            return { step: RECEIVER_STATES.INITIAL_STATE }
    }
}


const ReceiveContainer = ({ location }) => {


    const [state, dispatch] = useReducer(receiveReducer, {step: RECEIVER_STATES.INITIAL_STATE});

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search || '')
        const sessionId = queryParams.get('id');
        if(sessionId) {
            dispatch({ type: "SET_SESSION_ID", sessionId })
        } else {
            dispatch({type: RECEIVER_STATES.ERROR, message: 'Sorry, wrong link. Check that the link is the same as you have received from the bot.'})
        }
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            if (state.sessionId) {
                const metadata = await fetchMetadata(state.sessionId)
                if (metadata) {
                    dispatch({ type: "SET_METADATA", metadata })
                } else {
                    dispatch({
                        type: RECEIVER_STATES.ERROR,
                        message: 'Sorry, something went wrong. Try reloading the page or initiate a new password sharing process from the start.'
                    })
                }
            }
        })()
    }, [state.sessionId]);

    useEffect(() => {
        (async () => {
            if (!state.sessionId || !state.metadata) {
                return
            }

            const storedSecret = readKeypair(state.sessionId)
            if (!storedSecret || !storedSecret.privateKey) {
                const [privateKey, publicKey] = generateKeypair();
                saveKeypair(state.sessionId, { privateKey, publicKey })
                dispatch({type: RECEIVER_STATES.PUBLIC_KEY_GENERATED})
                const r = await uploadPublicKey(state.metadata, {publicKey})
                if (r) {
                    dispatch({type: RECEIVER_STATES.PUBLIC_KEY_SUBMITTED, publicKey, privateKey})
                } else {
                    dispatch({type: RECEIVER_STATES.ERROR, message: 'Error uploading public key: session expired'})
                }
            } else {
                dispatch({type: RECEIVER_STATES.PUBLIC_KEY_SUBMITTED, publicKey: storedSecret.publicKey, privateKey: storedSecret.privateKey})
            }
        })()
    }, [state.sessionId, state.metadata]);

    useEffect(() => {
            if(state.step !== RECEIVER_STATES.PUBLIC_KEY_SUBMITTED || !state.metadata){
                return
            }
            const interval = setInterval(async () => {
            const { encryptedPassword, secretPublicKey } = await downloadEncryptedData(state.metadata)
            if(encryptedPassword){
                dispatch({type: RECEIVER_STATES.PASSWORD_RECEIVED, encryptedPassword, secretPublicKey});
            }
        }, POLLING_INTERVAL)
        return () => clearInterval(interval)
    }, [state.step, state.metadata]);

    useEffect(() => {
        if(!state.privateKey || !state.secretPublicKey || !state.encryptedPassword){
            return
        }
        try {
            const decryptedPassword = decrypt(state.privateKey, state.secretPublicKey, state.encryptedPassword);
            dispatch({ type: RECEIVER_STATES.PASSWORD_DECRYPTED, decryptedPassword })
        } catch (e) {
            dispatch({type: RECEIVER_STATES.ERROR, message: `Error decrypting password: You have wrong or expired keys`})
        }
    },[state.privateKey,state.secretPublicKey,state.encryptedPassword])


    return (
        <Receive {...state} />
    );
};

export default ReceiveContainer;
