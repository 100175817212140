import React from 'react';
import styled from 'styled-components';

const GithubIcon = ({className}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M9.99908 0C4.47773 0 0 4.59042 0 10.2534C0 14.7834 2.86504 18.626 6.83876 19.9818C7.33908 20.0762 7.52141 19.7596 7.52141 19.4877C7.52141 19.2441 7.51281 18.5996 7.5079 17.7442C4.72636 18.3636 4.13948 16.3695 4.13948 16.3695C3.68458 15.185 3.02894 14.8696 3.02894 14.8696C2.121 14.2339 3.0977 14.2465 3.0977 14.2465C4.10142 14.3189 4.62936 15.3033 4.62936 15.3033C5.52135 16.8699 6.97013 16.4174 7.53983 16.1549C7.63068 15.4927 7.88913 15.0408 8.17459 14.7846C5.95414 14.5259 3.61951 13.646 3.61951 9.71711C3.61951 8.59799 4.00933 7.68217 4.64901 6.96588C4.54587 6.70656 4.20271 5.6636 4.74723 4.25242C4.74723 4.25242 5.58642 3.97673 7.49685 5.30356C8.2943 5.07571 9.15007 4.96241 10.0003 4.95801C10.8499 4.96241 11.7051 5.07571 12.5038 5.30356C14.413 3.97673 15.2509 4.25242 15.2509 4.25242C15.7967 5.6636 15.4535 6.70656 15.351 6.96588C15.9919 7.68217 16.3786 8.59799 16.3786 9.71711C16.3786 13.6561 14.0403 14.5228 11.8131 14.7765C12.1716 15.0931 12.4915 15.7187 12.4915 16.6748C12.4915 18.0457 12.4792 19.1516 12.4792 19.4877C12.4792 19.7622 12.6597 20.0813 13.1668 19.9812C17.1374 18.6223 20 14.7821 20 10.2534C20 4.59042 15.5223 0 9.99908 0Z"
                  />
        </svg>

    );
};

const StyledGithubIcon = styled(GithubIcon)`
  fill: #888888;
  &:hover {
    fill: #FFFFFF
  }
`

export default StyledGithubIcon;
