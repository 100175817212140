import { css } from 'styled-components'

const link = css`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.1px;
`

const h1 = css`
  font-family: 'JetBrains Mono', sans-serif;
  font-weight: 500;
  font-size: 42px;
  line-height: 46px;
`

const h2 = css`
  font-family: 'JetBrains Mono', sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
`

const button = css`
  font-family: 'JetBrains Mono', Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.2px;
`

const input = css`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.45px;
`

const inputLabel = css`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.3px;
`

const label1 = css`
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
`

const body1 = css`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
`

const smallText = css`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.2px;
`

export default {
  h1,
  h2,
  button,
  input,
  inputLabel,
  label1,
  body1,
  smallText,
  link,
}