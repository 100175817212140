import '../styles/main.css'
import '../styles/normalize.css'
import '../styles/fonts.css'
import '../styles/layout.css'

import React from 'react'
import ReactDOM from 'react-dom'
import {Router} from 'react-router'

import history from './history'
import App from './components/app'


ReactDOM.render(<Router history={history}><App /></Router>,
  document.getElementById('root')
)
