import React, {useState} from 'react'

import {decrypt} from '../utils'
import {readKeypair} from '../utils/storage'

import styled from 'styled-components'

import styles from './styles'
import Input from './input'
import InputLabel from './input-label'
import Button from './button'


const DEFAULT_COPY_LABEL = 'Copy Password to Clipboard'
const COPIED_COPY_LABEL = 'Copied!'

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText};
  margin-top: 24px;
  margin-bottom: 24px;
`

const P = styled.p`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const Error = styled.h1`
  ${styles.typography.body1}
  color: ${styles.colors.error};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

export default function({location}) {
  const [copyLabel, setCopyLabel] = useState(DEFAULT_COPY_LABEL)
  const [error, setError] = useState('')
  const queryParams = new URLSearchParams(location.search || '')
  const secret = queryParams.get('secret')
  const publicKey = queryParams.get('publicKey')
  const secretId = queryParams.get('id')
  const privateKey = readKeypair(secretId).privateKey

  const decrypted = !error && safeDecrypt(privateKey, publicKey, secret)

  function safeDecrypt(privateKey, publicKey, secret) {
    if (!privateKey) {
      setError(`Couldn't find private key`)
      return `Couldn't find private key`
    }
    if (!publicKey) {
      setError(`Couldn't find public key`)
      return `Couldn't find public key`
    }
    if (!secret) {
      setError(`Couldn't find encrypted secret`)
      return `Couldn't find encrypted secret`
    }
    let decrypted = ''
    try {
      decrypted = decrypt(decodeURIComponent(privateKey), decodeURIComponent(publicKey), secret)
    } catch (e) {
      console.info('e', e)
      setError(`Couldn't decrypt the secret`)
      return ''
    }
    if (!decrypted) {
      setError(`Couldn't decrypt the secret`)
      return `Couldn't decrypt the secret`
    }
    return decrypted
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(decrypted)
    setCopyLabel(COPIED_COPY_LABEL)
    setTimeout(_ => setCopyLabel(DEFAULT_COPY_LABEL), 500)
  }

  return <div className='form-wrapper'>
    <H1>Get Password</H1>
    <P>Here is the password that has been shared with you. Make sure to retrieve it now and store it in a safe place (e.g. password manager). Thank you for using our service!</P>
    {error && <Error>{error}</Error>}
    {!error && <>
    <InputLabel>Password</InputLabel>
    <Input
      width='100%'
      isPassword
      defaultValue={decrypted}
    />

    <div>
      <Button
        style={{width: '100%', marginTop: '24px'}}
        mode='hero'
        onClick={copyToClipboard}>
        {copyLabel}
      </Button>
    </div>
    </>}
  </div>
}
