import React, {useState} from 'react'
import styled from 'styled-components'

import styles from './styles'
import Input from './input'
import InputLabel from './input-label'
import Button from './button'
import Spinner from './spinner'

import Stepper from './stepper';

const DEFAULT_COPY_LABEL = 'Copy Password to Clipboard'
const COPIED_COPY_LABEL = 'Copied!'

const H1 = styled.h1`
  ${styles.typography.h1}
  color: ${styles.colors.mainText};
  margin-top: 24px;
  margin-bottom: 24px;
`

const P = styled.p`
  ${styles.typography.body1}
  color: ${styles.colors.secondaryText};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '24px'};
`

const Centered = styled.div`
  display:flex;
  flex-direction: row;
  height: ${props => props.height ? props.height : ' 100%'};
  justify-content: center;
  align-items: center;
`
const RECEIVER_STATES = {
  INITIAL_STATE: 'INITIAL_STATE',
  PUBLIC_KEY_GENERATED: 'PUBLIC_KEY_GENERATED',
  PUBLIC_KEY_SUBMITTED: 'PUBLIC_KEY_SUBMITTED',
  PASSWORD_RECEIVED: 'PASSWORD_RECEIVED',
  PASSWORD_DECRYPTED: 'PASSWORD_DECRYPTED',
  ERROR: 'ERROR'
}

function getStepperActiveIndexForState(state) {
    switch (state) {
        case RECEIVER_STATES.INITIAL_STATE:
            return -1;
        case RECEIVER_STATES.PUBLIC_KEY_GENERATED:
            return 0;
        case RECEIVER_STATES.PUBLIC_KEY_SUBMITTED:
            return 1;
        case RECEIVER_STATES.PASSWORD_DECRYPTED:
            return 2;
        default:
            return -1;
    }
}

const LEADS = {
  [RECEIVER_STATES.INITIAL_STATE]: (senderName) => (<P>{senderName} initiated sending you a password in a secure way using this service. The password will be sent encrypted with your one-time public key.</P>),
  [RECEIVER_STATES.PUBLIC_KEY_GENERATED]: (senderName) => (<P>{senderName} initiated sending you a password in a secure way using this service. The password will be sent encrypted with your one-time public key which is now generated.</P>),
  [RECEIVER_STATES.PUBLIC_KEY_SUBMITTED]: (senderName) => (<><P>{senderName} initiated sending you a password in a secure way using this service. The password will be sent encrypted with your one-time public key which is now generated.</P></>),
  [RECEIVER_STATES.PASSWORD_RECEIVED]: () => (<><P>The password is submitted, decrypting.</P></>),
  [RECEIVER_STATES.PASSWORD_DECRYPTED]: () => (<P>The password received and decrypted. Make sure to save it, as it won't be possible to retrieve it after leaving the page.</P>),
  [RECEIVER_STATES.ERROR]: () => (<P>Sorry, something went wrong. Try reloading the page or initiate a new password sharing process from the start.</P>),
}

export default function(props) {
  const {step, error, errorMessage, decryptedPassword, metadata} = props
    const senderName = (metadata && metadata.sender && metadata.sender.name)
        ? <strong style={{color: 'white'}}>{`@${metadata.sender.name}`}</strong>
        : <span>Someone</span>
  console.log('state', props)
  const [copyLabel, setCopyLabel] = useState(DEFAULT_COPY_LABEL)

  function copyToClipboard() {
    navigator.clipboard.writeText(decryptedPassword)
    setCopyLabel(COPIED_COPY_LABEL)
    setTimeout(_ => setCopyLabel(DEFAULT_COPY_LABEL), 500)
  }

  return <div className='form-wrapper'>
    <H1>Get The Password</H1>
    {LEADS[step](senderName)}
    <Stepper
      style={{marginTop: '40px'}}
      steps={[
        'Key generated',
        'Key submitted',
        'Password received'
      ]}
      activeStepIndex={getStepperActiveIndexForState(step)}
    />
    {step === RECEIVER_STATES.PUBLIC_KEY_SUBMITTED
      ? <P style={{marginTop: '24px'}}>Public key is submitted, you can close the page now and wait for the sender to submit the password.</P>
      : null}
    {renderFormState(step)}
    {error
      ? <P><span style={{color: styles.colors.error}}>{errorMessage}</span></P>
      : null}
  </div>

  function renderFormState(step) {
    if ((step === RECEIVER_STATES.INITIAL_STATE || step === RECEIVER_STATES.PUBLIC_KEY_GENERATED) && !error) {
      return (
        <Centered height={'100px'}>
          <Spinner scale={1.3} color={styles.colors.white} />
        </Centered>
      )
    } else if (step === RECEIVER_STATES.PASSWORD_DECRYPTED) {
      return (
        <>
          <InputLabel>Password</InputLabel>
          <Input
            width='100%'
            defaultValue={decryptedPassword}
            isPassword={true}
          />
          <div>
            <Button
              style={{width: '100%', marginTop: '24px'}}
              mode='hero'
              onClick={copyToClipboard}>
              {copyLabel}
            </Button>
          </div>
        </>
      )
    } else {
      return null
    }
  }
}
