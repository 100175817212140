import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import styles from './styles'

const BasicButtonTag = styled.button`
  ${styles.typography.button}
  box-sizing: border-box;
  padding: 12px 18px;
  display: inline-flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
`

const heroPrimaryColor = props => props.disabled ? '#525356' : styles.colors.mainPrimary;

const HeroButtonTag = styled(BasicButtonTag)`
  padding: 18px 24px;
  border-radius: 8px;
  border: 1px solid ${props => heroPrimaryColor(props)};
  background-color: ${props => heroPrimaryColor(props)};
  color: ${props => props.disabled ? '#818184' : styles.colors.white};
  &:hover, &:active {
    border: 1px solid ${props => props.disabled ? '#525356' : '#529CF9'};
    background-color: ${props => props.disabled ? '#525356' : '#529CF9'};
    color: ${props => props.disabled ? '#818184' : styles.colors.white};
  }
`

const regularBorderColorHover = props => props.disabled ? styles.colors.mineShaft : styles.colors.white
const regularTextColor = props => props.disabled ? '#636363' : '#8C8C8C'

const RegularButtonTag = styled(BasicButtonTag)`
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid ${styles.colors.mineShaft};
  background-color: ${props => props.disabled ? '#111111' : 'rgba(255, 255, 255, 0)'};
  color: ${props => regularTextColor(props)};
  &:hover, &:active {
    border: 1px solid ${props => regularBorderColorHover(props)};
    background-color: ${props => props.disabled ? '#111111' : 'rgba(255, 255, 255, 0)'};
    color: ${props => props.disabled ? '#636363' : 'white'};
  }
`

export class Button extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    mode: PropTypes.oneOf([
      'hero',
      'regular',
    ]),
  }

  render() {
    const {
      children,
      disabled,
      onClick,
      mode = 'regular',
      ...rest
    } = this.props

    let ButtonTag
    if (mode == 'hero') {
      ButtonTag = HeroButtonTag
    } else {
      ButtonTag = RegularButtonTag
    }

    return (
      <ButtonTag onClick={onClick} disabled={disabled} {...rest}>
        {children}
      </ButtonTag>
    )
  }
}

export default Button
